import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/bitwarden-rs",
  "title": "Switching from Bitwarden to Bitwarden RS",
  "started": "2020-04-06T00:00:00.000Z",
  "published": "2020-04-07T00:00:00.000Z",
  "backgroundImage": "safe",
  "tags": ["Bitwarden", "Password Manager"],
  "layoutClass": "bitwarden-rs",
  "thumbnail": "blog/2020-04-06-bitwarden-rs/bitwarden.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Password management`}</h2>
    <p>{`I have been using `}<a parentName="p" {...{
        "href": "https://bitwarden.com/"
      }}>{`Bitwarden`}</a>{` - an open source, self-hosted password manager for a little over
six months.
Before that, I used the password manager provided by Google, with it's nice sync feature between devices, so my
passwords were available on my phone as well as my work Mac and my personal machines.`}</p>
    <p>{`For quite some time, I have been attempting to move my data away from cloud hosted things, specifically Google's
infrastructure.
It has proved to be quite a long and slow process.`}</p>
    <p>{`The time came to switch away from Chrome, and back to Firefox.
Firefox has a similar sync feature for bookmarks, history, add-ons, open tabs, passwords and preferences.
Most of the syncing features worked very well, but I had quite a lot of problems with the password sync.`}</p>
    <p>{`I had exported all of my credentials from Chrome to a CSV file and imported them into Firefox, but the imported
credentials refused to sync to my other devices from there.
I understand it was because I enabled a master password to access them, and the mobile app to access the saved passwords
(Lockwise) used a PIN instead, or something security related.
Maybe it was because I enabled 2FA on my account - I don't really remember.
It all seemed a lot harder than it should be.
Either way, using Firefox to manage my passwords like I had done with Chrome seemed too problematic.`}</p>
    <p>{`I spent some time looking for an open source, self-hosted password manager that had an iOS app, 2FA, and seemed somewhat
modern.
I don't really remember any specific requirements any more, but Bitwarden seemed to fulfil what I was after at the time.`}</p>
    <p>{`Hosting my own password manager also means that I won't be storing my password database on someone else's computer.
It does however, put the onus on me to secure my infrastructure well enough.`}</p>
    <h2>{`Official Bitwarden`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://help.bitwarden.com/article/install-on-premise/"
      }}>{`installation`}</a>{` of Bitwarden seems rather simple on the face
of it, you download a script and run it.`}</p>
    <span className="prism-title">shell</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -Lso bitwarden.sh https://go.btwrdn.co/bw-sh
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` +x bitwarden.sh
./bitwarden.sh `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{`
./bitwarden.sh start`}</code></pre></div>
    <p>{`Edit the config file, then restart Bitwarden.`}</p>
    <span className="prism-title">shell</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`./bitwarden.sh restart`}</code></pre></div>
    <p>{`I'm not a huge fan of downloading scripts and just running them - though I run enough things that I download that I
probably shouldn't as concerned as I am.
As the rest of my infrastructure is configured using Ansible, I wanted write a quick Ansible role to set up and
configure Bitwarden for me in a repeatable way.
So I spent an unreasonable amount of time digging though what the installation script (and the sub-scripts that it
downloads and executes) did so that I could install it manually.`}</p>
    <p>{`This involved following significantly
`}<a parentName="p" {...{
        "href": "https://help.bitwarden.com/article/install-on-premise/#manual-docker-installations"
      }}>{`more complicated instructions`}</a>{`,
which included downloading a stub repository, generating keys, writing a lot of .env files and eventually starting the
service with docker-compose.
Docker compose starts quite a few rather resource thirsty containers in some sort of micro-service setup which seems
pretty neat.`}</p>
    <p>{`I'm pretty sure I should have made my Ansible play just run the standard installation commands!`}</p>
    <p>{`Bitwarden also consumed a `}<em parentName="p">{`lot`}</em>{` of disk space!
For some reason, Bitwarden did not use the Overlay2 filesystem, but instead saved the image layers using the VFS storage
driver.
This directory reached 36.5GB, which consumed far too much SSD space on my home server (I only have 128GB of usable
SSD storage).
To preserve SSD space, I mounted a directory from my higher capacity but slower spinning disk array into the Bitwarden
LXD container and moved the data there instead.`}</p>
    <p>{`The iOS app and browser extensions seem to work pretty well.
The web interface is basic but quite functional albeit a little slow at times.`}</p>
    <p>{`Compared to the other services my home server runs, Bitwarden was definitely the most resource hungry thing I ran.`}</p>
    <p>{`I run system containers through LXD on my home server, so the metrics below are the entire container as measured by
systemd on the host.`}</p>
    <div className="image-row">
  <Image alt="Official Bitwarden CPU use" path="blog/2020-04-06-bitwarden-rs/official-cpu-use.png" mdxType="Image" />
  <Image alt="Official Bitwarden memory use" path="blog/2020-04-06-bitwarden-rs/official-memory-use.png" mdxType="Image" />
    </div>
    <p>{`I can't guarantee that my Ansible deployment of the Official Bitwarden service was 100% correct, so maybe the high
resource use was caused by me misconfiguring something.
I left it alone while I worked on other things.
Every so often I'd take a look at my server graphs which show the CPU and memory use of the containers
The resource use - especially CPU got to me, and when looking for issues on the Bitwarden Github repository, I found an
issue describing what I saw an `}<a parentName="p" {...{
        "href": "https://github.com/bitwarden/server/issues/563"
      }}>{`issue`}</a>{` describing what I saw.`}</p>
    <p>{`I don't know what it was doing with all those resources. I'm pretty confident that it should be doing pretty much
nothing nearly all the time - except when I invoke it though one of it's interfaces.`}</p>
    <h2>{`Bitwarden RS`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/dani-garcia/bitwarden_rs"
      }}>{`Bitwarden RS`}</a>{` is an unofficial implementation of the Bitwarden password
manager written in Rust.
It uses significantly fewer resources and only requires a single docker container to run it.
It also allows the use of the normally paid features like organisations and reports.
For my home usage I don't think I'll have a use for organisations, but the reports may be useful.`}</p>
    <p>{`Installation is significantly simpler than the official release, and the config file is a single JSON config file.
Environment variables can be used instead, but the JSON config overrides environment variables and can be modified by
the admin interface.`}</p>
    <span className="prism-title">shell</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`docker run -d `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
	--name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'bitwardenrs'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
	-p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{`:80 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
	-v /var/lib/bitwardenrs:/data/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
	bitwardenrs/server`}</code></pre></div>
    <p>{`This is easily achieved with Ansible's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker_container`}</code>{` module.`}</p>
    <p>{`There are a few image variants available.
The standard `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bitwardenrs/server`}</code>{` image supports using a SQLite database.
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bitwardenrs/server-mysql`}</code>{` adds support for connecting to MySQL databases.
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bitwardenrs/server-postgres`}</code>{` - as you might have guessed - adds support for connecting to PostgreSQL databases.`}</p>
    <p>{`I doubt for my scale it'd make much difference which I'd use, but I decided that a real database backend might be a
little faster, and maybe safer to backup?
I've not used SQLite enough to know how it handles a backup of the database
file taking place during a write operation.
I know MySQL and Postgres are absolutely fine with this case.
It would be trivial to have set up a new docker container for the database for Bitwarden RS, but as I already have a
MySQL server on my home server, I used that.`}</p>
    <div className="image-row">
  <Image alt="Bitwarden RS CPU use" path="blog/2020-04-06-bitwarden-rs/rs-cpu-use.png" mdxType="Image" />
  <Image alt="Bitwarden RS memory use" path="blog/2020-04-06-bitwarden-rs/rs-memory-use.png" mdxType="Image" />
    </div>
    <p>{`It's nice to see the resource use get lost in the background noise.`}</p>
    <p>{`The disk space used by the OverlayFS storage driver for Bitwarden RS has reached 170MB.`}</p>
    <p>{`The interface feels a little more responsive, but I lack any metrics to show whether this is the case or not.`}</p>
    <h2>{`Data migration`}</h2>
    <p>{`Migrating my vault contents was easy as Bitwarden has an export feature which allows you to download your vault as a
JSON file (just be aware that this file is unencrypted).
Then the data file can be imported into the new account.`}</p>
    <h2>{`Comparison`}</h2>
    <p>{`I'm happy that the Bitwarden RS implementation is wasting fewer of my home server's resource.
Now that it's getting warmer outside, I don't need the extra few watts of heat from it to keep the house warm either.`}</p>
    <p>{`As you might already have noticed, I like my metrics, and these last ones show the resources saved nicely.`}</p>
    <div className="image-row">
  <Image alt="Compare CPU use" path="blog/2020-04-06-bitwarden-rs/compare-cpu-use.png" mdxType="Image" />
  <Image alt="Compare memory use" path="blog/2020-04-06-bitwarden-rs/compare-memory-use.png" mdxType="Image" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      